import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import SpecBubble from "../../animations/spec/spec_bubble";
import CirclesSwiper from "../../components/swipers/circles";
import GalleryTwo from "../../components/swipers/gallery-two";
import GalleryIconMobile from "../../components/swipers/icon-gallery-mobile";
import BubbleAnimationTwo from "../../animations/bubble/bubble-two";
import "./icon.scss";
import Bios from "../../components/bios/bio";
import useIsMobile from "../../hooks/useIsMobile";

const Icon = () => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const [mapContent, setMapContent] = useState<boolean>(true);
    const isMobile = useIsMobile();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (!hasScrolled && scrollPosition > 1) {
                setHasScrolled(true);
            }
            if (hasScrolled && scrollPosition <= 1) {
                setHasScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrolled]);

    const variants = {
        initial: { opacity: 1, y: 0 },
        animate: {
            y: "-100%",
            opacity: 1,
            transition: { duration: 1, ease: "easeInOut" },
        },
        reverse: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: "easeInOut" },
        },
    };

    return (
        <>
            <div className="icon-one">
                <motion.div
                    initial="initial"
                    animate={hasScrolled ? "animate" : "reverse"}
                    variants={variants}
                    className="copy-container"
                >
                    <div className="copy-wrapper">
                        <h4>ICONIC IN EVERY WAY</h4>
                        <h2>
                            A unique space crafted by, and&nbsp;
                            <span
                                onClick={() =>
                                    (window.location.href = "/icon/#icons")
                                }
                                className="green-copy link"
                            >
                                home to true icons of British design
                            </span>
                            , the building's heritage is matched by it's&nbsp;
                            <span className="green-copy">
                                location IN the heart&nbsp;
                            </span>
                            of historic Shad Thames.
                        </h2>
                    </div>
                </motion.div>

                <div className="image-wrapper">
                    <div className="content">
                        <div className="copy">
                            <h2>
                                DESIGNED BY HOPKINS.
                                <br />
                                BUILT FOR MELLOR.
                                <br />
                                HOME TO CONRAN.
                                <br />
                                REIMAGINED BY SQUIRE.
                            </h2>
                            <p>
                                Designed by Sir Michael and Patty Hopkins, 22
                                Shad Thames was constructed in 1991 as part of a
                                unique collaboration with renowned designer
                                David Mellor MBE.
                                <br />
                                <br />
                                The building’s most iconic occupier was the
                                world-renowned designer and entrepreneur Sir
                                Terence Conran, who not only based his
                                influential design practice Conran and Partners
                                at the address, but also made his home in a
                                spacious penthouse apartment.
                                <br />
                                <br />
                                Now it has been re-imagined by architects Squire
                                and Partners, to design-led, contemporary office
                                space.
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contain-wrapper">
                <div className="icon-one-mobile mobile contain">
                    <p>
                        Designed by Sir Michael and Patty Hopkins, 22 Shad
                        Thames was constructed in 1991 as part of a unique
                        collaboration with renowned designer David Mellor MBE.
                        <br />
                        <br />
                        The building’s most iconic occupier was the
                        world-renowned designer and entrepreneur Sir Terence
                        Conran, who not only based his influential design
                        practice Conran and Partners at the address, but also
                        made his home in a spacious penthouse apartment.
                        <br />
                        <br />
                        Now it has been re-imagined by architects Squire and
                        Partners, to design-led, contemporary office space.
                        <br />
                        <br />
                    </p>
                    <h4>Above: Sir Terrance Conran</h4>
                </div>
            </div>
            <Bios />

            <div className="contain-wrapper">
                <div className="contain icon-three">
                    <div className="left-side">
                        <motion.div
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 1, transition: 0.5 }}
                            className="copy-wrapper"
                        >
                            <h3>
                                SIR TERENCE CONRAN. <br /> AN ICONIC LEGACY
                            </h3>
                            <p>
                                Blending a keen eye for design and a strong
                                business acumen, Sir Terence Conran has left a
                                lasting mark on British and international
                                culture through his ambition to make
                                well-designed, well-made and affordable home
                                décor and furnishings available to all.
                            </p>
                        </motion.div>

                        <div className="image-containers">
                            <div className="image-wrapper">
                                <p className="caption">
                                    Habitat catalogues — 1971
                                </p>
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/habitat-1.jpg)",
                                    }}
                                ></div>
                            </div>
                            <div className="image-wrapper">
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/habitat-2.jpg)",
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="image-containers">
                            <div className="image-wrapper">
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/2.jpg)",
                                    }}
                                ></div>
                                <p className="caption">
                                    The Design Museum, Shad Thames 1989-2016
                                </p>
                            </div>
                            <div className="image-wrapper">
                                <div
                                    className="image"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/3.jpg)",
                                    }}
                                ></div>
                                <p className="caption">Textile pattern, 1953</p>
                            </div>
                        </div>
                        <div className="copy-wrapper">
                            <p>
                                Founded in 1989, the Design Museum was at the
                                heart of Sir Terence Conran’s vision for a
                                transformed and redeveloped Shad Thames.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="icon-four">
                <BubbleAnimationTwo />
            </div>
            <div className="contain-wrapper black-back">
                <div className="contain icon-five">
                    <GalleryIconMobile
                        images={[
                            "../images/icon/7.jpg",
                            "../images/icon/8.jpg",
                            "../images/icon/9.jpg",
                        ]}
                        captions={[
                            "Workers unloading cargo along the wharves",
                            "Horselydown stairs",
                            "Catwalks over Shad Thames",
                        ]}
                    />
                    <p className="copy mobile pad">
                        With London Bridge and Bermondsey stationsa short walk
                        away, the Conran Building is well connected to the heart
                        of the city.
                    </p>
                    <p className="copy mobile">
                        Nestled alongside St Saviour’s Dock, Shad Thames is a
                        unique location with its cobbled streets and Victorian
                        warehouses lining the riverside. Formerly a bustling tea
                        trade centre, it now hosts trendy eateries, boutique
                        shops, and chic apartments, all set against the iconic
                        Tower Bridge backdrop.
                    </p>
                    <div className="images-container">
                        <div className="image-container">
                            <p></p>
                            <div
                                className="image-wrapper"
                                style={{
                                    backgroundImage:
                                        "url(../images/icon/5.jpg)",
                                }}
                            ></div>
                            <p></p>
                        </div>
                        <div className="image-container">
                            <p></p>
                            <div
                                className="image-wrapper"
                                style={{
                                    backgroundImage:
                                        "url(../images/icon/4.jpg)",
                                }}
                            ></div>
                            <p className="caption">
                                Retail under the catwalks along Shad Thames
                            </p>
                        </div>
                    </div>

                    <motion.div
                        whileInView={{ y: 0, opacity: 1 }}
                        initial={{ y: 20, opacity: 0 }}
                        transition={{ delay: 1, transition: 0.5 }}
                        className="copy-wrapper"
                    >
                        <h4>LOCATION</h4>
                        <h3>
                            UNIQUE STREETS RICH WITH HISTORY, ON THE DOORSTEP OF
                            A LONDON ICON
                        </h3>
                        <p className="desktop">
                            Nestled alongside St Saviour’s Dock, Shad Thames is
                            a unique location with its cobbled streets and
                            Victorian warehouses lining the riverside. Formerly
                            a bustling tea trade centre, it now hosts trendy
                            eateries, boutique shops, and chic apartments, all
                            set against the iconic Tower Bridge backdrop.
                        </p>
                        <p className="desktop">
                            With London Bridge and Bermondsey stations a short
                            walk away, the Conran Building is well connected to
                            the heart of the city.
                        </p>
                    </motion.div>
                </div>
            </div>

            <div className="contain-wrapper black-back">
                <div className="contain icon-six">
                    <motion.div
                        whileInView={{ y: 0, opacity: 1 }}
                        initial={{ y: 20, opacity: 0 }}
                        transition={{ delay: 1, transition: 0.5 }}
                        className="copy-wrapper"
                    >
                        <h4>1865 → TODAY</h4>
                        <h3>
                            HERITAGE
                            <br />
                            WITHOUT QUESTION
                        </h3>
                        <p>
                            Once known as ‘the larder of London', the warehouses
                            of Butler’s Wharf were built between 1865–1873,
                            leading Shad Thames to become the largest warehouse
                            complex in the UK of the time — housing and
                            supplying vast quantities of commodities like tea,
                            coffee spices and malt.
                        </p>
                        <p>
                            Part of the area of the river known as the ‘Pool of
                            London’, its place on the banks of the Thames and
                            proximity to the heart of central London made it the
                            ideal location for the delivery and distribution of
                            these products across the country.
                        </p>
                        <div className="image-wrapper">
                            <img src="../images/icon/6.svg" />
                        </div>
                    </motion.div>
                    <div className="image-hero">
                        <div className="caption-wrapper">
                            <div
                                className="image-wrapper"
                                style={{
                                    backgroundImage:
                                        "url(../images/icon/7.jpg)",
                                }}
                            ></div>
                            <div className="onside">

                            <p className="caption">
                                Workers unloading cargo along the wharves
                            </p>
                            </div>
                        </div>
                        <div className="image-container">
                            <div className="caption-wrapper">
                                <div
                                    className="image-wrapper"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/8.jpg)",
                                    }}
                                ></div>
                                <p className="caption">Horsleydown stairs</p>
                            </div>
                            <div className="caption-wrapper">
                                <div
                                    className="image-wrapper"
                                    style={{
                                        backgroundImage:
                                            "url(../images/icon/9.jpg)",
                                    }}
                                ></div>
                                <p className="caption">
                                    Catwalks over Shad Thames
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contain-wrapper black-back horizontal-line">
                <div className="contain icon-seven">
                    <SpecBubble
                        text={"RESTAURANTS WITHIN A FEW MINUTES WALK"}
                        number={"41"}
                        textColor="#65724A"
                        circleColor="#65724A"
                    />
                </div>
            </div>
            <div className="contain-wrapper black-back">
                <div className="contain icon-eight">
                    <div className="content-wrapper">
                        <SpecBubble
                            text={"PUBS & BARS WITHIN A FEW MINUTES WALK"}
                            number={"35"}
                            textColor="#65724A"
                            circleColor="#65724A"
                        />
                        <motion.div
                            whileInView={{ y: 0, opacity: 1 }}
                            initial={{ y: 20, opacity: 0 }}
                            transition={{ delay: 1, transition: 0.5 }}
                            className="copy-wrapper"
                        >
                            <h4>LOCAL AREA</h4>
                            <h3>
                                NARROW STREETS WHERE <br /> CHOICES ARE WIDE
                                OPEN
                            </h3>
                            <p>
                                The area has undergone a complete redevelopment
                                and regeneration, beginning in the 1980’s after
                                Sir Terence Conran led the successful bid for
                                the historic site to transform the area into
                                more than one million square feet of retail,
                                cultural, work and living space.
                            </p>
                        </motion.div>
                        <div className="mobile specs-wrappers">
                            <SpecBubble
                                text={"PUBS & BARS WITHIN A FEW MINUTES WALK"}
                                number={"35"}
                                textColor="#65724A"
                                circleColor="#65724A"
                            />
                            <SpecBubble
                                text={"RESTAURANTS WITHIN A FEW MINUTES WALK"}
                                number={"41"}
                                textColor="#65724A"
                                circleColor="#65724A"
                            />
                            <SpecBubble
                                text={
                                    "PARKS & GREEN SPACE WITHIN A FEW MINUTES WALK"
                                }
                                number={"05"}
                                textColor="#65724A"
                                circleColor="#65724A"
                            />
                        </div>
                        <div className="placeholder" />
                    </div>
                </div>
            </div>
            <div className="contain-wrapper black-white-back">
                <div className="contain">
                    <CirclesSwiper
                        images={[
                            "../images/icon/gallery/1.png",
                            "../images/icon/gallery/2.png",
                            "../images/icon/gallery/3.png",
                            "../images/icon/gallery/1.png",
                            "../images/icon/gallery/2.png",
                            "../images/icon/gallery/3.png",
                            "../images/icon/gallery/1.png",
                            "../images/icon/gallery/2.png",
                            "../images/icon/gallery/3.png",
                            "../images/icon/gallery/1.png",
                            "../images/icon/gallery/2.png",
                            "../images/icon/gallery/3.png",
                            "../images/icon/gallery/1.png",
                            "../images/icon/gallery/2.png",
                            "../images/icon/gallery/3.png",
                        ]}
                    />
                </div>
            </div>

            <div className="contain-wrapper">
                <div className="contain icon-nine">
                    <div className="swiper-container">
                        <GalleryTwo
                            images={[
                                "../images/icon/gallery-two/1.jpg",
                                "../images/icon/gallery-two/2.jpg",
                                "../images/icon/gallery-two/3.jpg",
                                "../images/icon/gallery-two/4.jpg",
                                "../images/icon/gallery-two/5.jpg",
                                "../images/icon/gallery-two/6.jpg",
                            ]}
                            captions={[
                                "Borough Market",
                                "Your own private gym at Solo 60",
                                "The Barrel Project",
                                "Vinegar Yard",
                                "Hay's Galleria",
                                "Butler's Wharf Chop House",
                            ]}
                        />
                    </div>
                    <motion.div
                        whileInView={{ y: 0, opacity: 1 }}
                        initial={{ y: 20, opacity: 0 }}
                        transition={{ delay: 1, transition: 0.5 }}
                        className="copy-wrapper"
                    >
                        <h3>EVEN MORE TO DISCOVER ALONG THE SOUTH BANK</h3>
                        <p>
                            Stretching along the riverside is a vibrant and
                            bustling area of the city, filled with restaurants,
                            green spaces and cultural venues. From Borough
                            Market's myriad of stalls, to the 200 barrels of
                            beer from Barrel Project at the London Beer Factory
                            — the possibilities are endless.
                        </p>
                    </motion.div>
                </div>
            </div>

            <div className="contain-wrapper white-back">
                <div className="contain icon-ten">
                    <div className="image-wrapper">
                        {mapContent || isMobile ? (
                            <AnimatePresence>
                                <motion.img
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    src="../images/icon/map.svg"
                                />
                            </AnimatePresence>
                        ) : (
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                src="../images/icon/map2.png"
                            />
                        )}
                        <div className="spec-wrapper">
                            <SpecBubble
                                text={
                                    "PARKS & GREEN SPACE WITHIN A FEW MINUTES WALK"
                                }
                                number={"05"}
                                textColor="#65724A"
                                circleColor="#65724A"
                            />
                        </div>
                        {mapContent ? (
                            <div className="content-section">
                                <div className="content">
                                    <h3>LOCAL OCCUPIERS</h3>
                                    <div className="table">
                                        <div className="column">
                                            <div className="pin">
                                                <div className="number">
                                                    <p>1</p>
                                                </div>
                                                <p>EVENT COMMUNICATIONS</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>2</p>
                                                </div>
                                                <p>LEXICA</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>3</p>
                                                </div>
                                                <p>NHS LPP</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>4</p>
                                                </div>
                                                <p>SAE MEDIA</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>5</p>
                                                </div>
                                                <p>WITHSECURE</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>6</p>
                                                </div>
                                                <p>REC</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>7</p>
                                                </div>
                                                <p>WALSH ASSOCIATES</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>8</p>
                                                </div>
                                                <p>VERSO IM</p>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="pin">
                                                <div className="number">
                                                    <p>9</p>
                                                </div>
                                                <p>THE FOLIO SOCIETY</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>10</p>
                                                </div>
                                                <p>TRANSMISSION</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>11</p>
                                                </div>
                                                <p>PENSON ARCHITECTURE</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>12</p>
                                                </div>
                                                <p>ZOOPLA</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>13</p>
                                                </div>
                                                <p>ALTO</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>14</p>
                                                </div>
                                                <p>THE MAVERICK GROUP</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>15</p>
                                                </div>
                                                <p>EAST ALPHA</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>16</p>
                                                </div>
                                                <p>MOLSON COORS</p>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="pin">
                                                <div className="number">
                                                    <p>17</p>
                                                </div>
                                                <p>NORTON ROSE FULBRIGHT</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>18</p>
                                                </div>
                                                <p>JSS SEARCH</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>19</p>
                                                </div>
                                                <p>PWC</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>20</p>
                                                </div>
                                                <p>EY</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>21</p>
                                                </div>
                                                <p>MONTAGU PRIVATE SOCIETY</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>22</p>
                                                </div>
                                                <p>LANDOR ASSOCIATES</p>
                                            </div>
                                            <div className="pin">
                                                <div className="number">
                                                    <p>23</p>
                                                </div>
                                                <p>CYGNET SEARCH</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="btn-wrapper">
                            <div
                                onClick={() => setMapContent(true)}
                                className={`btn ${mapContent && "active"}`}
                            >
                                <h4>LOCAL OCCUPIERS</h4>
                            </div>
                            <div
                                onClick={() => setMapContent(false)}
                                className={`btn ${!mapContent && "active"}`}
                            >
                                <h4>CONNECTIONS</h4>
                            </div>
                        </div>
                    </div>
                    <div className="mobile map">
                        <img src="../images/icon/map2.png" alt="map" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Icon;
